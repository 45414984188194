<script>
import AppModal from "@/components/Ui/AppModal.vue";
import { mapState } from "vuex";

export default {
  name: "MainNotice",
  components: { AppModal },
  emits: ["close", "submit", "cancel"],

  props: {
    isShowNotice: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    content: {
      type: String,
      default: "",
    },

    submitText: {
      type: String,
      default: "Сохранить",
    },

    submitDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      window_width: (state) => state.system.window_width,
    }),
  },
};
</script>

<template>
  <AppModal v-if="isShowNotice" class="modal" @close="$emit('close')">
    <div class="main-notice">
      <h4>{{ title }}</h4>

      <p>{{ content }}</p>

      <div class="main-notice__footer">
        <button
          type="button"
          class="button button_full-width"
          :disabled="submitDisabled"
          @click.stop="$emit('submit')"
        >
          {{ submitText }}
        </button>

        <button
          class="button button_secondary button_full-width"
          type="button"
          @click.stop="$emit('close')"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>
</template>

<style lang="scss" scoped>
.main-notice {
  min-width: 200px;
  z-index: 100;

  > h4 {
    color: $text-primary;
    @include h-2;
  }

  > p {
    margin-top: 16px;
    color: $text-third;
    @include m-text;
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
    flex-direction: column;
  }
}

.modal {
  ::v-deep(.app-modal__wrapper) {
    @media (min-width: 768px) {
      padding: 40px;
      width: 350px;
    }
  }
}
</style>
