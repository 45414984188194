const matchers = {
  "community count limit": "Лимит по кол-ву сообществ",
  "user not found": "Пользователь не найден",
};

const SYSTEM_MESSAGE = "Что-то пошло не так";

const responseErrorMessageHandler = (error, defaultMessage) => {
  let newMessage = SYSTEM_MESSAGE;
  const message = error?.response?.data?.message;

  if (message) {
    newMessage = matchers[message] ?? defaultMessage ?? SYSTEM_MESSAGE;
  }
  return new Error(newMessage);
};

export { responseErrorMessageHandler };
export default responseErrorMessageHandler;
