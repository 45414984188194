<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import AppModal from "@/components/Ui/AppModal.vue";
import { useStore } from "vuex";
import CustomTariffIcon from "@/components/CustomTariff/CustomTariffIcon.vue";

const props = defineProps({
  tariff: {
    type: Object,
    required: true,
  },

  count: {
    type: Number,
    required: true,
  },
});

const store = useStore();

const emit = defineEmits(["select"]);

const isOpen = ref(false);

const customValue = ref("");

watch(props, (v) => {
  customValue.value = v.count.toString();
});

const handleToggleDialog = () => {
  isOpen.value = !isOpen.value;
};

const handleIncrement = () => {
  emit("select", { tariff: props.tariff, count: props.count + 1 });
};

const handleDecrement = () => {
  if (props.count <= 0) {
    return;
  }
  emit("select", { tariff: props.tariff, count: props.count - 1 });
};

const handleSelectCustomValue = () => {
  emit("select", { tariff: props.tariff, count: Number(customValue.value) });
  isOpen.value = false;
};
</script>

<template>
  <AppModal
    v-if="store.state.system.window_width > 1023 && isOpen"
    title="Количество тарифов"
    @close="handleToggleDialog"
  >
    <div class="modal-title">Количество тарифов</div>
    <div class="drawer-content">
      <AppInput v-model="customValue" mask="###" />
      <button
        type="button"
        class="button button_full-width"
        @click="handleSelectCustomValue"
      >
        Готово
      </button>
    </div>
  </AppModal>
  <AppDrawer
    v-else-if="isOpen"
    title="Количество тарифов"
    @close="handleToggleDialog"
  >
    <div class="drawer-content">
      <AppInput v-model="customValue" mask="###" />
      <button
        type="button"
        class="button button_full-width"
        @click="handleSelectCustomValue"
      >
        Готово
      </button>
    </div>
  </AppDrawer>
  <div class="counter">
    <div class="tariff">
      <CustomTariffIcon :tariff="tariff" />
      <div class="info">
        <div class="name">{{ tariff.name }}</div>
        <div class="price">{{ tariff.price_original }} ₽ / мес.</div>
      </div>
    </div>
    <div class="picker">
      <button type="button" class="element" @click="handleDecrement">
        <i class="icon-minus"></i>
      </button>
      <button type="button" class="count" @click="handleToggleDialog">
        {{ count }}
      </button>
      <button type="button" class="element" @click="handleIncrement">
        <i class="icon-plus"></i>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-title {
  @include h-3;
}
.drawer-content {
  display: flex;
  flex-direction: column;
  gap: $base-space * 6;
  margin-top: $base-space * 6;
}
.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #efefef;
  border-radius: 16px;
  padding: $base-space * 6 $base-space * 4;

  .tariff {
    display: flex;
    align-items: center;
    gap: $base-space * 3;

    .info {
      display: flex;
      flex-direction: column;

      .name {
        @include m-text;
      }

      .price {
        @include m-text;
        color: $text-fifth;
      }
    }
  }

  .picker {
    display: flex;
    appearance: $base-space;
    gap: $base-space * 2;

    .element {
      i {
        width: 24px;
        height: 24px;
        background: $text-fifth;
      }

      &:active {
        i {
          background: $text-accent;
        }
      }
    }

    .count {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      @include s-text;
      border-radius: 8px;
    }
  }
}
</style>
