import HttpClient from "@/api";
import { getFilesFromPhotos } from "@/utils/files";

const state = {
  myBlog: null,
  currentPost: null,
  createPost: null,
  isLoading: false,
  isLoadMoreLoading: false,
  isPageLoaded: false,
  popularityPosts: null,
  freshPosts: null,
  myPosts: null,
  archivePosts: null,
  mediaUploadProgress: [],
  postUploadProgress: 0,
};

const getters = {
  totalUploadProgress: (state) => {
    const totalMediaProgress = state.mediaUploadProgress.reduce(
      (acc, progress) => acc + progress,
      0
    );
    const mediaCount = state.mediaUploadProgress.length;
    const mediaProgress = mediaCount ? totalMediaProgress / mediaCount : 0;
    return Math.round((mediaProgress + state.postUploadProgress) / 2);
  },
};

const mutations = {
  SET_MY_BLOG(store, payload) {
    store.myBlog = payload;
  },

  SET_CREATE_POST(store, payload) {
    store.myBlog = payload;
  },

  SET_CURRENT_POST(store, payload) {
    store.currentPost = payload;
  },

  RESET_CURRENT_POST(store) {
    store.currentPost = null;
  },

  RESET_BLOG(store) {
    store.myBlog = null;
  },

  SET_POPULARITY_POSTS(store, payload) {
    if (!store.popularityPosts) {
      store.popularityPosts = [];
    }
    store.popularityPosts = [
      ...store.popularityPosts,
      ...payload.popularityPosts,
    ];
  },

  SET_FRESH_POSTS(store, payload) {
    if (!store.freshPosts) {
      store.freshPosts = [];
    }
    store.freshPosts = [...store.freshPosts, ...payload.freshPosts];
  },

  SET_MY_POSTS(store, payload) {
    if (!store.myPosts) {
      store.myPosts = [];
    }
    store.myPosts = [...store.myPosts, ...payload.myPosts];
  },

  SET_ARCHIVE_POSTS(store, payload) {
    if (!store.archivePosts) {
      store.archivePosts = [];
    }
    store.archivePosts = [...store.archivePosts, ...payload.archivePosts];
  },

  RESET_BLOG_POSTS(store) {
    store.popularityPosts = null;
    store.freshPosts = null;
    store.myPosts = null;
    store.archivePosts = null;
  },

  RESET_ALL_POSTS(store) {
    store.myBlog = null;
    store.popularityPosts = null;
    store.freshPosts = null;
    store.myPosts = null;
    store.archivePosts = null;
  },

  SET_MEDIA_UPLOAD_PROGRESS(state, { index, progress }) {
    state.mediaUploadProgress.splice(index, 1, progress);
  },
  ADD_MEDIA_UPLOAD_PROGRESS(state) {
    state.mediaUploadProgress.push(0);
  },
  RESET_MEDIA_UPLOAD_PROGRESS(state) {
    state.mediaUploadProgress = [];
  },
  SET_POST_UPLOAD_PROGRESS(state, progress) {
    state.postUploadProgress = progress;
  },
  RESET_POST_UPLOAD_PROGRESS(state) {
    state.postUploadProgress = 0;
  },
  RESET_UPLOAD_PROGRESS(state) {
    state.mediaUploadProgress = [];
    state.postUploadProgress = 0;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },
};

const actions = {
  async createBlog(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const { title, photos, description } = payload;
      const response = await HttpClient.postForm(`api/blog`, {
        title,
        description,
        file: getFilesFromPhotos(photos)[0],
      });

      store.commit("SET_MY_BLOG", response.data.data);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async editBlog(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const { title, photos, description } = payload;
      const blog_id = store.state.myBlog.id;
      if (photos.length) {
        await HttpClient.postForm(`api/blog/${blog_id}/media`, {
          file: getFilesFromPhotos(photos)[0],
        });
      }

      await HttpClient.postForm(`api/blog/${blog_id}`, {
        title,
        description,
      });
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async createPost(commit, payload) {
    try {
      commit.commit("TOGGLE_LOADING", true);
      const { status, title, body, preview_image } = payload;
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          commit.commit("SET_POST_UPLOAD_PROGRESS", percentCompleted);
        },
      };
      await HttpClient.postForm(
        `api/blog/posts`,
        {
          status,
          title,
          body: JSON.stringify(body),
          preview_image,
        },
        config
      );
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit.commit("TOGGLE_LOADING", false);
      commit.commit("RESET_POST_UPLOAD_PROGRESS");
    }
  },

  async editPost({ commit }, payload) {
    try {
      commit("TOGGLE_LOADING", true);
      const { status, title, body, preview_image, post_id } = payload;
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          commit("SET_POST_UPLOAD_PROGRESS", percentCompleted);
        },
      };
      await HttpClient.postForm(
        `api/blog/posts/${post_id}`,
        {
          status,
          title,
          body: JSON.stringify(body),
          preview_image,
        },
        config
      );
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("TOGGLE_LOADING", false);
      commit("RESET_POST_UPLOAD_PROGRESS");
    }
  },

  async setMediaPost(commit, { file, index }) {
    const params = {
      file,
    };
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        commit.commit("SET_MEDIA_UPLOAD_PROGRESS", {
          index,
          progress: percentCompleted,
        });
      },
    };

    try {
      const response = await HttpClient.postForm(
        `api/blog/posts/media`,
        params,
        config
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteMediaPost(_, payload) {
    try {
      const response = await HttpClient.delete(
        `api/blog/posts/media/${payload.imageId}`
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteBlog(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      await HttpClient.delete(`api/blog/${payload.id}`);
      store.commit("RESET_BLOG", true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async deletePost(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      await HttpClient.delete(`api/blog/posts/${payload.post_id}`);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async publicPost(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      if (!payload.isPublished) {
        await HttpClient.postForm(`api/blog/posts/${payload.post_id}/publish`);
      } else {
        await HttpClient.postForm(`api/blog/posts/${payload.post_id}/archive`);
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async getBlog(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const response = await HttpClient.get(`api/blog/${payload.blog_id}`);

      store.commit("SET_MY_BLOG", response.data.data);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async getCurrentPost(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const response = await HttpClient.get(
        `api/blog/posts/${payload.post_id}`
      );

      store.commit("SET_CURRENT_POST", response.data.data);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async sendInArchive(_, payload) {
    try {
      const { post_id } = payload;

      await HttpClient.post(`api/blog/posts/${post_id}/archive`);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async sendInPublished(_, payload) {
    try {
      const { post_id } = payload;
      await HttpClient.post(`api/blog/posts/${post_id}/publish`);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getBlogPosts(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const { blog_id, status, sort, page, blog_page } = payload;
      const isBlogPage = blog_page ? blog_page : false;
      const params = {
        blog_id: blog_id ? parseFloat(blog_id) : null,
        status: status ? status : "",
        sort: sort,
        per_page: 15,
        page: page,
      };
      const response = await HttpClient.get(`api/blog/posts`, { params });
      if (
        (blog_id === "" && status === "published" && sort === "popularity") ||
        (isBlogPage &&
          blog_id !== "" &&
          status === "published" &&
          sort === "popularity")
      ) {
        store.commit("SET_POPULARITY_POSTS", {
          popularityPosts: response.data.data,
        });
      }
      // if (blog_id === "" && status === "published" && sort === "published_at") {
      //   store.commit("SET_FRESH_POSTS", {
      //     freshPosts: response.data.data,
      //   });
      // }
      if (status === "published" && sort === "published_at") {
        store.commit("SET_FRESH_POSTS", {
          freshPosts: response.data.data,
        });
      }
      if (status === "my" && sort === "published_at") {
        store.commit("SET_MY_POSTS", {
          myPosts: response.data.data,
        });
      }
      if (blog_id !== "" && status === "archive" && sort === "published_at") {
        store.commit("SET_ARCHIVE_POSTS", {
          archivePosts: response.data.data,
        });
      }

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async subscribeBlog(_, data) {
    try {
      const response = await HttpClient.postForm(
        `api/blog/${data.blog_id}/subscribe`
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async unsubscribeBlog(_, data) {
    try {
      const response = await HttpClient.postForm(
        `api/blog/${data.blog_id}/unsubscribe`
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
