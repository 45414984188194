<script>
export default {
  name: "AppTextarea",
  emits: ["update:modelValue", "update:currentHeight"],

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },

    currentHeight: {
      type: String,
      default: "0px",
    },

    required: {
      type: Boolean,
      default: false,
    },

    isBlog: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    error: {
      type: String,
      default: "",
    },

    autoHeight: {
      type: Boolean,
      default: false,
    },

    autoHeightMin: {
      type: Number,
      default: undefined,
    },

    autoHeightMax: {
      type: Number,
      default: undefined,
    },

    hideError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
    },

    counter: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: Number,
      default: 5000,
    },

    height: {
      type: Number,
      default: 150,
    },
  },

  computed: {
    textAreaHeight() {
      return this.height + "px";
    },

    counterValue() {
      return `${this.modelValue.length}/${this.maxLength}`;
    },

    maxLengthValue() {
      return this.modelValue.length === this.maxLength;
    },
  },

  methods: {
    focus() {
      const textarea = document.getElementById(this.id);
      textarea.focus();
    },

    setDefaultHeight() {
      document.getElementById("textarea-container").style.height =
        this.textAreaHeight;

      document.getElementById(this.id).style.height = this.textAreaHeight;

      this.$emit("update:currentHeight", this.textAreaHeight);
    },

    updateTextareaValue(event) {
      if (this.autoHeight) {
        event.target.style.height = "0px";
        event.target.style.height = `${Math.max(
          Math.min(event.target.scrollHeight, this.autoHeightMax ?? 9999),
          this.autoHeightMin ?? 0
        )}px`;

        document.getElementById("textarea-container").style.height =
          event.target.style.height;

        this.$emit("update:currentHeight", event.target.style.height);
      }
      this.$emit("update:modelValue", event.target.value);
    },
  },

  mounted() {
    if (this.isBlog) {
      const textarea = this.$refs.textareaBlog;
      textarea.style.height = "auto"; // Сбрасываем высоту
      textarea.style.height = textarea.scrollHeight + "px";
    }
    document.getElementById("textarea-container").style.height =
      document.getElementById(this.id)?.style?.height ?? this.height;
  },
};
</script>

<template>
  <div class="app-textarea">
    <label v-if="label" class="label" :for="id" :class="{ required: required }">
      {{ label }}
      <span
        v-if="counter"
        :class="{ counter_max: maxLengthValue }"
        class="label__counter counter"
      >
        {{ counterValue }}
      </span>
    </label>

    <div
      id="textarea-container"
      class="textarea"
      :class="{ textarea_error: error }"
    >
      <textarea
        :id="id"
        ref="textareaBlog"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        @input="updateTextareaValue"
      />
    </div>

    <span v-if="error && !hideError" class="error">
      {{ error }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.app-textarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  gap: $base-space * 2;
  width: 100%;

  .label {
    width: 100%;
    position: relative;

    .counter {
      position: absolute;
      right: 4px;

      &_max {
        color: $text-error;
      }
    }
  }

  > label {
    color: $text-third;
    @include s-text();
    padding: 0 0 0 ($base-space);

    &.required {
      &::after {
        content: " *";
        color: $text-error;
      }
    }
  }

  > .textarea {
    width: 100%;
    height: v-bind(textAreaHeight);

    > textarea {
      background: none;
      width: 100%;
      border: 1px solid $stroke-third;
      border-radius: 16px;
      height: v-bind(textAreaHeight);
      padding: ($base-space * 4) ($base-space * 5);
      @include m-text();
      color: $text-primary;
      resize: none;

      &:focus {
        outline: none;
        border-color: $stroke-accent;
      }

      &::placeholder {
        color: $text-fifth;
      }
    }

    &_error {
      > textarea {
        border-color: $stroke-error;
      }
    }
  }

  > span {
    padding: 0 0 0 ($base-space);
  }
}
</style>
