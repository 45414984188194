<script>
import { mapActions, mapMutations, mapState } from "vuex";
import TrialPeriodNotification from "@/components/TrialPeriodNotification.vue";

export default {
  name: "App",
  components: { TrialPeriodNotification },

  computed: {
    ...mapState({
      notification: (state) => state.system.notification,
      isTrialPeriodTariffOpen: (state) => state.system.isTrialPeriodTariffOpen,
    }),

    currentIconNotification() {
      if (this.notification.type === "error") {
        return "warning";
      } else if (this.notification.type === "success") {
        return "check";
      } else if (this.notification.type === "blog-post") {
        return "activate";
      } else if (this.notification.type === "blog-post-archive") {
        return "document";
      } else if (this.notification.type === "blog-delete") {
        return "trash";
      } else {
        return "blog";
      }
    },
  },

  methods: {
    ...mapActions({
      loadRegions: "system/loadRegions",
      shutdown: "notifications/shutdown",
    }),

    ...mapMutations({
      setScrollHeight: "system/SET_SCROLL_HEIGHT",
      setWindowWidth: "system/SET_WINDOW_WIDTH",
      setTrialNotice: "system/SET_TRIAL_TARIFF_NOTICE",
    }),

    closeTrialPeriodNotification() {
      this.setTrialNotice(false);
    },
  },

  created() {
    window.addEventListener("scroll", this.setScrollHeight);
    this.setScrollHeight();
    window.addEventListener("resize", this.setWindowWidth);
    this.setWindowWidth();
  },

  mounted() {
    this.loadRegions();
  },

  unmounted() {
    window.removeEventListener("scroll", this.setScrollHeight);
    window.removeEventListener("resize", this.setWindowWidth);
    this.shutdown();
  },
};
</script>

<template>
  <RouterView />

  <Transition>
    <div
      v-if="notification"
      :class="{ 'wrapper-notification_error': notification.type === 'error' }"
      class="wrapper-notification"
    >
      <div class="wrapper-notification__notification notification">
        <i :class="`icon-${currentIconNotification}`" />
        <p>{{ notification.message }}</p>
      </div>
    </div>
  </Transition>
  <!--:is-open = "isTrialPeriodTariffOpen" -->
  <TrialPeriodNotification
    :is-open="false"
    @close="closeTrialPeriodNotification"
  />
</template>

<style scoped lang="scss">
.wrapper-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-accent;
  grid-gap: $base-space * 2;
  width: 400px;
  left: calc(50% - 200px);
  bottom: $base-space * 8;
  z-index: 120;
  border-radius: 12px;
  position: fixed;
  padding: ($base-space * 4) ($base-space * 4);

  @media (max-width: 1023px) {
    bottom: 78px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 16px);
    left: 8px;
  }

  &_error {
    background-color: $text-error;

    .wrapper-notification__notification {
      background-color: $text-error;
    }
  }

  .notification {
    @include main-shadow();
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;
    align-items: center;

    > i {
      width: 24px;
      height: 24px;
      background-color: $text-light;
    }

    > p {
      @include m-text();
      color: $text-light;
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
