<script setup>
import ProfileCard from "@/components/Profile/ProfileCard.vue";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import NotificationsDropdown from "@/components/Notifications/NotificationsDropdown.vue";
import { activeScroll, disableScroll } from "@/package/helpers/scrollActions";

const store = useStore();

const route = useRoute();

const { push } = useRouter();

const isProfileDropdownOpen = ref(false);

const user = computed(() => store.state.user.user);

const unreadNotification = computed(
  () => store.state.notifications.unreadNotification
);
const unreadMessage = computed(() => store.state.notifications.unreadMessages);

const isSelectorOpened = ref(false);
const isNotificationOpened = ref(false);

const authorCourses = computed(() => {
  if (user?.value.tariff_id === 1) {
    return {
      link: "",
      title: "",
      inMore: "",
    };
  }
  return {
    link: "AuthorCoursesView",
    title: "Авторские курсы",
    icon: "speaker",
    inMore: true,
  };
});

const investProgram = computed(() => {
  if (user?.value.tariff_id === 1) {
    return {
      link: "",
      title: "",
      inMore: "",
    };
  }
  return {
    link: "InvestmentProgramView",
    title: "Партнерство",
    icon: "cashback",
    inMore: true,
  };
});

// const foreignCompany = computed(() => {
//   if (user?.value.tariff_id === 1) {
//     return {
//       link: "",
//       title: "",
//       inMore: false,
//     };
//   }
//   return {
//     link: "ForeignCompanyView",
//     title: "Иностранные партнеры",
//     inMore: true,
//   };
// });

const items = ref([
  { link: "CalendarView", title: "Календарь" },
  { link: "NewsView", title: "Новости" },
  // { link: "SosView", title: "Красная кнопка" },
  {
    link: "CoursesView",
    title: "Бизнес-университет",
  },

  { link: "EventsView", title: "Мероприятия", icon: "promocode", inMore: true },
  {
    link: "CommunitiesView",
    title: "Сообщества",
    icon: "people",
    inMore: true,
  },

  investProgram.value,
  {
    link: "CompaniesView",
    title: "Компании",
    icon: "business",
    inMore: true,
  },
  authorCourses.value,
  {
    link: "ServicesView",
    title: "Полезные сервисы",
    icon: "development",
    inMore: true,
  },
  {
    link: "SupportCentersView",
    title: "Центр поддержки",
    icon: "comments",
    inMore: true,
  },

  {
    link: "BlogView",
    title: "Блог",
    icon: "blog",
    inMore: true,
  },
  // foreignCompany.value,
]);

const defaultItems = computed(() =>
  items.value.filter((item) => !item?.inMore && item?.inMore !== "")
);
const moreItems = computed(() => items.value.filter((item) => item?.inMore));

const handleOpenProfileDropdown = () => {
  isProfileDropdownOpen.value = true;
};

const handleCloseProfileDropdown = () => {
  isProfileDropdownOpen.value = false;
};

const openSosPage = () => {
  push({
    name: "SosView",
  });
};

watch(
  () => isNotificationOpened.value,
  (newValue) => {
    if (newValue) {
      disableScroll();
    } else {
      activeScroll();
    }
  }
);

const handleCloseMoreDropdown = () => {
  isSelectorOpened.value = false;
};
const openChatPage = () => {
  push({
    name: "ChatChannelsView",
  });
};

const handleCloseNotificationDropdown = () => {
  isNotificationOpened.value = false;
};

const handleChangeRegionsModalView = () => {
  store.commit("system/CHANGE_REGIONS_MODAL_VIEW", true);
};
</script>

<template>
  <header class="layout-header">
    <div class="container">
      <RouterLink :to="{ name: 'CalendarView' }">
        <img src="../../../assets/images/logo.svg" alt="logotype" />
      </RouterLink>

      <nav>
        <RouterLink
          v-for="item in defaultItems"
          :key="item?.link"
          :to="{ name: item?.link }"
        >
          <i :class="`icon-${item?.icon}`" />
          {{ item?.title }}
        </RouterLink>
        <div class="more-sections">
          <button
            class="more-sections__more-button more-button"
            type="button"
            @click="isSelectorOpened = !isSelectorOpened"
          >
            <i
              class="more-button__icon icon icon-service-fill"
              :class="{ icon_rotated: isSelectorOpened }"
            />
          </button>
          <Transition name="dropdown-fade" mode="out-in">
            <div
              v-if="isSelectorOpened"
              v-click-outside="handleCloseMoreDropdown"
              class="more-sections__options"
            >
              <nav>
                <button
                  v-for="item in moreItems"
                  :key="item.link"
                  @click="isSelectorOpened = false"
                >
                  <RouterLink
                    :to="{ name: item.link, query: { ...route.query } }"
                    :class="{ 'sos-button': item.icon === 'sos' }"
                  >
                    <i :class="`icon-${item.icon}`" />
                  </RouterLink>
                  <span>{{ item.title }}</span>
                </button>
              </nav>
            </div>
          </Transition>
        </div>
      </nav>
      <div class="layout-header__right right">
        <button
          type="button"
          class="geolocation"
          @click="handleChangeRegionsModalView"
        >
          <i class="icon-geolocation" />
          {{
            store.getters["user/getSelectedRegion"]
              ? store.getters["user/getSelectedRegion"].name
              : "Все регионы"
          }}
        </button>

        <div class="right__notification-section notification-section">
          <button
            type="button"
            class="notification-section__notification notification"
            @click="openChatPage"
          >
            <span v-if="unreadMessage > 0" class="notification__online-circle">
              {{ unreadMessage > 99 ? unreadMessage + "+" : unreadMessage }}
            </span>
            <i class="icon-messenger" />
          </button>
        </div>

        <div class="right__notification-section notification-section">
          <button
            type="button"
            class="notification-section__notification notification"
            @click="isNotificationOpened = true"
          >
            <span
              v-if="unreadNotification > 0"
              class="notification__online-circle"
              >{{
                unreadNotification > 99
                  ? unreadNotification + "+"
                  : unreadNotification
              }}</span
            >
            <i class="icon-notification"></i>
          </button>
          <Transition name="dropdown-fade" mode="out-in">
            <NotificationsDropdown
              v-if="isNotificationOpened"
              v-click-outside="handleCloseNotificationDropdown"
              @closeDropdownHandler="isNotificationOpened = false"
            />
          </Transition>
        </div>

        <button type="button" class="right__sos sos" @click="openSosPage">
          <i class="icon-sos" />
        </button>

        <div class="profile-navigation">
          <button
            type="button"
            class="profile-navigation__avatar"
            @click="handleOpenProfileDropdown"
          >
            <span
              class="avatar"
              :class="{ avatar_rotated: isProfileDropdownOpen }"
              :style="{
                backgroundImage: `url(${
                  store.state.user.user?.media.length
                    ? store.state.user.user.media[0].url.full_size
                    : ''
                })`,
              }"
            >
              <i
                v-if="!store.state.user.user?.media.length"
                class="icon-person"
              />
            </span>
          </button>
          <Transition name="dropdown-fade" mode="out-in">
            <div
              v-if="isProfileDropdownOpen"
              v-click-outside="handleCloseProfileDropdown"
              class="profile-navigation__dropdown"
            >
              <ProfileCard @close="handleCloseProfileDropdown" />
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.layout-header {
  background: $background-light;
  display: none;
  border-bottom: 1px solid $stroke-fourth;
  position: fixed;
  width: 100%;
  z-index: 19;

  @include _md {
    display: flex;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $base-space * 8;

    .notification-section {
      position: relative;

      .notification {
        position: relative;

        &__online-circle {
          @include xxs-text-bold;
          display: inline-block;
          position: absolute;
          z-index: 1;
          min-width: 14px;
          height: 14px;
          right: 0;
          padding: 0 4px;
          border-radius: 16px;
          color: $text-light;
          border: 1.5px solid $text-light;
          background: $text-error;
        }

        > i {
          width: 32px;
          height: 32px;
          background: $text-primary;
        }
      }
    }

    .sos {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 4px;
      background: $background-ultralightlight-accent;

      > i {
        width: 32px;
        height: 32px;
        background: $text-error;
      }
    }
  }

  > .container {
    padding: ($base-space * 4) 0;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;

    > a {
      > img {
        width: 60px;
      }
    }

    > nav {
      margin-left: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > a {
        color: $text-primary;

        &:not(:first-of-type) {
          margin-left: $base-space * 8;
        }

        &.router-link-active {
          color: $text-accent;
        }
      }

      .more-sections {
        margin-left: $base-space * 8;
      }
    }
  }

  .profile-navigation {
    position: relative;

    &__avatar {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $stroke-fourth;
      border-radius: 50%;

      > i {
        width: 32px;
        height: 32px;
        background: $text-accent;
      }

      &:hover {
        border: 2px solid $stroke-third;
      }

      > .avatar {
        width: 48px;
        height: 48px;

        transition: transform 0.2s ease-in-out;

        &_rotated {
          position: fixed;
          transform: scale(1.14, 1.14);
        }
      }
    }

    &__dropdown {
      position: absolute;
      right: 0;
      top: calc(100% + 8px);
      width: 400px;
      background: $background-light;
      @include main-shadow();
      border-radius: 16px;
      padding: ($base-space * 8);
      z-index: 10;
    }
  }

  .more-sections {
    position: relative;

    .more-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space;
      color: $text-primary;
      @include m-text();
      padding: ($base-space * 2) 0;

      .icon {
        width: 32px;
        height: 32px;
        background-color: $background-dark-gray;
        transition: transform 0.2s ease-in-out;

        &_rotated {
          transform: rotate(-90deg);
        }
      }
    }

    &:hover {
      & .more-sections__options {
        display: block;
      }
    }

    &__options {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 10;
      width: 432px;
      display: block;

      > nav {
        margin: ($base-space * 2) 0 0;
        padding: 24px;
        @include main-shadow();
        background: $background-light;
        border-radius: 12px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        > button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          height: auto;
          width: 81px;

          > a {
            height: 56px;
            width: 56px;
            background: $background-ultralightlight-accent;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            padding: 4px;
            text-align: center;
            padding: ($base-space * 2);
            flex-flow: column;
            gap: calc($base-space / 2);
            color: $text-fourth;
            @include xs-text-bold();

            &.router-link-active {
              background: $background-accent;

              > i {
                background: $text-light;
                width: 32px;
                height: 32px;
              }
            }

            > i {
              background: $text-accent;
              width: 32px;
              height: 32px;
            }
          }

          > span {
            height: 32px;
            width: 81px;
            @include xs-text-bold;
            color: $text-primary;
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .layout-header {
    &__right {
      gap: $base-space * 6;
    }

    > .container {
      > nav {
        margin-left: 50px;

        > a {
          &:not(:first-of-type) {
            margin-left: $base-space * 6;
          }
        }

        .more-sections {
          margin-left: $base-space * 6;
        }
      }
    }
  }
}

.dropdown-fade-enter-active {
  animation: openTabs 0.1s linear forwards;

  @keyframes openTabs {
    from {
      opacity: 0;
    }

    to {
      opacity: 80%;
    }
  }
}

.dropdown-fade-leave-active {
  animation: closeTabs 0.1s linear forwards;

  @keyframes closeTabs {
    from {
      opacity: 80%;
    }

    to {
      opacity: 0;
    }
  }
}
</style>
