import Centrifuge from "centrifuge";
import HttpClient from "@/api";
class ChatService {
  constructor() {
    this.url = import.meta.env.VITE_APP_WS || "";
  }

  newConnectionStart() {
    const centrifuge = new Centrifuge(this.url);

    centrifuge.on("connect", function (connectCtx) {
      console.log(connectCtx);
    });

    centrifuge.on("disconnect", function (disconnectCtx) {
      console.log(disconnectCtx);
    });

    return centrifuge;
  }

  /**
   * Метод проверяет существует ли экземпляр
   * @returns Centrifuge
   */
  connectionStart() {
    if (!this.centrifuge) {
      this.centrifuge = this.newConnectionStart();
    }

    return this.centrifuge;
  }

  async getToken() {
    if (localStorage.tokenWs) {
      return localStorage.tokenWs;
    }

    try {
      const response = await HttpClient.get("api/me/broadcast-token");
      localStorage.setItem("tokenWs", response.data.data.token);

      return response.data.data.token;
    } catch (error) {
      return new Error("Ошибка коннекта");
    }
  }

  async connectOnChat() {
    const centrifuge = this.connectionStart();
    const token = await this.getToken();

    centrifuge.setToken(token);
    centrifuge.connect();
  }

  disconnect() {
    const centrifuge = this.connectionStart();

    centrifuge.disconnect();
  }
}

export default new ChatService();
