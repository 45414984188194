import HttpClient from "@/api";

const state = {
  company: null,
  isLoading: false,
};

const mutations = {
  RESET_COMPANY(store) {
    store.company = null;
    store.isLoading = false;
  },

  UPDATE_COMPANY(store, payload) {
    store.company = payload.company;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },
};

const actions = {
  async loadCompany(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);

      const response = await HttpClient.get(`api/companies/${payload}`);
      if (!response.data) {
        throw new Error("No data");
      }
      store.commit("UPDATE_COMPANY", {
        company: response.data.data,
      });
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
