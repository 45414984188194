<script>
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AppModal from "@/components/Ui/AppModal.vue";
import AppCropperImage from "@/components/Ui/AppCropperImage.vue";

export default {
  name: "UserAvatar",
  components: { AppCropperImage, AppModal, AppDrawer },

  data() {
    return {
      show_update_modal: false,
    };
  },

  computed: {
    ...mapState({
      window_width: (state) => state.system.window_width,
      user: (state) => state.user.user,
    }),

    ...mapGetters({
      isDesktop: "system/isDesktop",
    }),

    currentModal() {
      if (this.isDesktop) {
        return AppModal;
      }

      return AppDrawer;
    },
  },

  methods: {
    ...mapActions({
      uploadUserAvatar: "user/uploadUserAvatar",
      deleteUserAvatar: "user/deleteUserAvatar",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    setDataImage(blob) {
      const formData = new FormData();

      formData.append("file", blob);

      this.uploadUserAvatar(formData).then(() => {
        this.show_update_modal = false;
      });
    },

    deleteAvatar() {
      this.deleteUserAvatar();
      this.show_update_modal = false;
    },
  },
};
</script>

<template>
  <component
    :is="currentModal"
    v-if="show_update_modal"
    @close="show_update_modal = false"
  >
    <div class="avatar-modal">
      <div class="avatar-modal">
        <h3>Фото профиля</h3>

        <div class="default-modal__actions">
          <AppCropperImage
            button-title="Загрузить фото"
            theme-btn="full-size-fill"
            circle
            @getCroppedImage="setDataImage"
          />

          <button
            v-if="user?.media.length"
            type="button"
            class="button button_secondary"
            @click="deleteAvatar"
          >
            Удалить фото
          </button>
        </div>
      </div>
    </div>
  </component>

  <button
    type="button"
    class="avatar"
    :style="{
      backgroundImage: `url(${
        user?.media.length ? user.media[0].url.full_size : ''
      })`,
    }"
    @click="show_update_modal = true"
  >
    <i v-if="!user?.media.length" class="icon-person" />
  </button>
</template>

<style scoped lang="scss">
.avatar-modal {
  width: 370px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: $base-space * 2;

  @media (max-width: 1023px) {
    width: 100%;
  }

  > .default-modal__actions {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    > button {
      width: 100%;
    }
  }
}
</style>
