import HttpClient from "@/api";
import responseErrorMessageHandler from "@/utils/responseErrorMessageHandler";

const state = {
  course: null,
  isSubscribed: false,
  isLoading: false,
  isSubscribing: false,
};

const mutations = {
  RESET_COURSE(store) {
    store.course = null;
    store.isLoading = false;
  },

  UPDATE_COURSE(store, payload) {
    store.course = payload.course;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },

  TOGGLE_SUBSCRIBING(store, payload) {
    store.isSubscribing = payload ?? !store.isSubscribing;
  },

  SET_SUBSCRIBED(store, payload) {
    store.isSubscribed = payload ?? !store.isSubscribing;
  },
};

const actions = {
  async checkSubscribed(store, payload) {
    try {
      await HttpClient.get(`api/courses/${payload.id}/events`);
      store.commit("SET_SUBSCRIBED", true);
    } catch {
      store.commit("SET_SUBSCRIBED", false);
    }
  },

  async loadCourse(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);

      const response = await HttpClient.get(`api/courses/${payload.id}`);
      if (!response.data) {
        throw new Error("No data");
      }
      store.commit("UPDATE_COURSE", {
        course: response.data.data,
      });
      await store.dispatch("checkSubscribed", { id: payload.id });
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async subscribe(store) {
    try {
      store.commit("TOGGLE_SUBSCRIBING", true);

      const response = await HttpClient.post(
        `api/courses/${store.state.course.id}/join`
      );
      if (!response.data) {
        throw new Error("No data");
      }

      store.commit("SET_SUBSCRIBED", true);
      return Promise.resolve(true);
    } catch (error) {
      store.commit("SET_SUBSCRIBED", false);
      return Promise.reject(
        responseErrorMessageHandler(error, "Не удалось загрузить курс")
      );
    } finally {
      store.commit("TOGGLE_SUBSCRIBING", false);
    }
  },

  async unsubscribe(store) {
    try {
      store.commit("TOGGLE_SUBSCRIBING", true);

      const response = await HttpClient.post(
        `api/courses/${store.state.course.id}/leave`
      );
      if (!response.data) {
        throw new Error("No data");
      }

      store.commit("SET_SUBSCRIBED", false);
      return Promise.resolve(true);
    } catch (error) {
      store.commit("SET_SUBSCRIBED", true);
      return Promise.reject(
        responseErrorMessageHandler(error, "Не удалось загрузить курс")
      );
    } finally {
      store.commit("TOGGLE_SUBSCRIBING", true);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
