<script setup>
import { ref, defineProps, defineEmits, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { Cropper, CircleStencil, RectangleStencil } from "vue-advanced-cropper";
import AppModal from "@/components/Ui/AppModal.vue";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.compact.css";

const emit = defineEmits(["getCroppedImage", "closeModal"]);

const props = defineProps({
  buttonTitle: {
    type: String,
    default: "Загрузить",
  },

  maxMegabyte: {
    type: Number,
    default: 5,
  },

  aspectRatio: {
    type: Number,
    default: 1,
  },

  circle: {
    type: Boolean,
    default: false,
  },

  themeBtn: {
    type: String,

    validator: (value) =>
      ["border", "fill", "full-size-border", "full-size-fill", ""].includes(
        value
      ),

    default: "",
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  isVisibleBtn: {
    type: Boolean,
    default: true,
  },

  changedFile: {
    type: Object,
    default: null,
  },

  mountedOpenContext: {
    type: Boolean,
    default: false,
  },

  minPixels: {
    type: Number,
    default: 512,
  },

  maxPixels: {
    type: Number,
    default: 2048,
  },
});

const store = useStore();

const currentMinPixels = computed(() => props.minPixels); // Преобразование изображения
const currentMaxPixels = computed(() => props.maxPixels); // Преобразование изображения

const MAX_FILE_SIZE = 1024 * 1024 * props.maxMegabyte;
const LIMIT_PIXEL = {
  min: currentMinPixels.value,
  max: currentMaxPixels.value,
};

const isVisibleCropperModal = ref(false);
const inputImageRef = ref(null);
const loadedImage = ref(null);
const cropperRef = ref(null);
const isDisabledBtnCrop = ref(true);

const linkToImages = computed(() => URL.createObjectURL(loadedImage.value)); // Преобразование изображения

const classBtn = computed(() => {
  switch (props.themeBtn) {
    case "border":
      return "button button_secondary";
    case "fill":
      return "button";
    case "full-size-border":
      return "button button_secondary button_full-width";
    case "full-size-fill":
      return "button button_full-width";
    default:
      return "";
  }
});

const currentStencil = props.circle ? CircleStencil : RectangleStencil; // Выборка трафарета в зависимости от пропса

const toggleVisibleCropperModal = (value) => {
  isVisibleCropperModal.value = value ?? !isVisibleCropperModal.value;
};

const handleClickLoadFile = () => {
  inputImageRef.value.click();
};

const getFile = (image) => {
  if (!props.isVisibleBtn && image) {
    return image;
  }

  if (inputImageRef.value.files.length) {
    return inputImageRef.value.files[0];
  }

  return null;
};

/**
 * Обработка изобраения
 * Вызывается после после изменения инпута
 * @param {Event} _
 */
const loadFile = (_, image) => {
  const file = getFile(image);
  const reader = new FileReader();

  if (!file) {
    return;
  }

  if (file.size > MAX_FILE_SIZE) {
    store.commit("system/SET_NOTIFICATION", {
      type: "error",
      message: `Размер файла не должен превышать ${props.maxMegabyte}мб`,
    });

    return;
  }

  reader.onload = (event) => {
    const img = new Image();

    img.onload = () => {
      const { width, height } = img;

      if (width < LIMIT_PIXEL.min || height < LIMIT_PIXEL.min) {
        store.commit("system/SET_NOTIFICATION", {
          type: "error",
          message: `Разрешение изображения должен быть от ${LIMIT_PIXEL.min}px до ${LIMIT_PIXEL.max}px`,
        });

        return;
      }

      loadedImage.value = file;
      toggleVisibleCropperModal(true);
    };

    img.src = event.target.result;
  };

  reader.readAsDataURL(file);
};

/**
 * Обработчик обрезки изображения
 */
const handleClickBtnCrop = () => {
  const { canvas } = cropperRef.value.getResult(); // Обращаемся для получения контекста холста

  canvas.toBlob((blob) => {
    emit("getCroppedImage", blob); // полученный blob изображения отправляем родителю
  });

  toggleVisibleCropperModal(false);
};

const onLoadedImageToCropper = () => {
  isDisabledBtnCrop.value = false;
};

const closeModal = () => {
  toggleVisibleCropperModal(false);
};

onMounted(() => {
  if (props.mountedOpenContext) {
    queueMicrotask(() => {
      inputImageRef.value.click();
    });
  }

  if (!props.isVisibleBtn && props.changedFile) {
    loadFile(null, props.changedFile);
  }
});
</script>

<template>
  <AppModal
    v-if="isVisibleCropperModal"
    class="crop-modal"
    is-have-header
    is-mobile-full
    @close="closeModal"
  >
    <Cropper
      ref="cropperRef"
      class="crop-modal__cropper"
      :src="linkToImages"
      :stencil-props="{
        aspectRatio,
      }"
      :stencil-component="currentStencil"
      min-width="300"
      @ready="onLoadedImageToCropper"
    />

    <button
      class="button crop-modal__btn-crop"
      :disabled="isDisabledBtnCrop"
      @click.prevent="handleClickBtnCrop"
    >
      Обрезать
    </button>
  </AppModal>

  <button
    v-if="isVisibleBtn"
    :class="classBtn"
    :disabled="disabled"
    @click.stop.prevent="handleClickLoadFile"
  >
    {{ buttonTitle }}
  </button>

  <input
    ref="inputImageRef"
    type="file"
    style="display: none"
    accept="image/jpeg,image/png"
    @change="loadFile"
    @click="
      (e) => {
        e.target.value = null;
      }
    "
  />
</template>

<style lang="scss" scoped>
.crop-modal {
  ::v-deep(.wrapper__header) {
    display: flex !important;
  }

  ::v-deep(.wrapper) {
    @media (max-width: 1023px) {
      padding: 0 !important;
      display: grid;
      grid-auto-columns: 100vw;
      grid-template-rows: 44px calc(100% - 116px) max-content;
    }
  }

  &__cropper {
    height: 398px;
    width: 398px;
    background: #ddd;
    margin: 0 21px 24px;

    @media (max-width: 1023px) {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__btn-crop {
    width: 100%;

    @media (max-width: 1023px) {
      width: calc(100% - (($base-space * 10)));
      justify-self: center;
      margin: ($base-space * 2) 0;
    }
  }
}
</style>
