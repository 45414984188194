<script setup>
import CitiesModal from "@/components/Modals/CitiesModal.vue";
import TemplateMain from "@/components/Layouts/MainLayout/TemplateMain.vue";
import TemplateHeader from "@/components/Layouts/MainLayout/TemplateHeader.vue";
import TemplateMobileNavBar from "@/components/Layouts/MainLayout/TemplateMobileNavBar.vue";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import ShareModal from "@/components/ShareModal.vue";
import FloatingMobileWrapper from "@/components/Ui/FloatingMobileWrapper.vue";
import TemplateMobileHeader from "@/components/Layouts/MainLayout/TemplateMobileHeader.vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

const { push } = useRouter();
const route = useRoute();

const store = useStore();

const noticeTimer = () => {
  const today = new Date();
  today.setHours(14, 0, 0);

  const todayFormat = moment(today).format("HH:mm:ss");

  const isNoticeToday = localStorage.getItem("isNoticeToday");
  const noticeTodayFormat = moment(isNoticeToday).format("HH:mm:ss");

  const rangeHour = moment
    .utc(moment.duration(todayFormat) - moment.duration(noticeTodayFormat))
    .format("HH");
  const rangeMinute = moment
    .utc(moment.duration(todayFormat) - moment.duration(noticeTodayFormat))
    .format("mm");
  const rangeSecond = moment
    .utc(moment.duration(todayFormat) - moment.duration(noticeTodayFormat))
    .format("ss");

  const numberRangeHour = parseInt(rangeHour);
  const numberRangeMinute = parseInt(rangeMinute);
  const numberRangeSecond = parseInt(rangeSecond);
  const range =
    numberRangeHour * 3600000 +
    numberRangeMinute * 60000 +
    numberRangeSecond * 1000;

  if (noticeTodayFormat < todayFormat) {
    setTimeout(() => {
      store.commit("system/SET_TRIAL_TARIFF_NOTICE", true);
    }, range);
  }
};

const getInfoUser = () => {
  if (localStorage.token) {
    store
      .dispatch("user/loadUserInformation")
      .then(() => {
        if (
          !store.state.user.user.first_name ||
          !store.state.user.user.last_name ||
          !store.state.user.user.email
        ) {
          push({ name: "LoginView" });
        }
      })
      .finally(() => {
        Promise.all([
          store.dispatch("user/loadUserPromoCodes"),
          store.dispatch("tariffs/loadTariffsList"),
        ]).then(() => {
          if (store.state.user.user.tariff_id === 1) {
            noticeTimer();
          }
          store.dispatch("notifications/init");
          store.dispatch("notifications/getUnreadCount");
        });
      });
    localStorage.setItem("isNoticeToday", moment(Date.now()).toISOString());
  } else {
    if (route.name === "BlogView") {
      push({ name: "BlogView" });
    } else if (route.name === "BlogPage") {
      push({
        name: "BlogPage",
        params: { id: route.params.id },
      });
    } else {
      push({
        name: "BlogPostPage",
        params: { id: route.params.id },
        query: { filter: route.query.filter },
      });
    }
  }
};

onMounted(() => {
  getInfoUser();
});

const isAppLoaded = computed(() => {
  if (
    (!localStorage.token && route.name === "BlogView") ||
    (!localStorage.token && route.name === "BlogPage") ||
    (!localStorage.token && route.name === "BlogPostPage")
  ) {
    return true;
  } else {
    return (
      store.state.system.regions &&
      store.state.tariffs.tariffs &&
      store.state.user.user
    );
  }
});

const isAuth = computed(
  () =>
    store.state.system.regions &&
    store.state.tariffs.tariffs &&
    store.state.user.user
);

const isShowNavBar = computed(() => {
  return (
    route.name !== "ProfileCustomTariffPayment" &&
    (route.name !== "ChatCurrentChannelView" ||
      (route.query?.type && route.query?.id))
  );
});
</script>

<template>
  <template v-if="isAppLoaded">
    <CitiesModal v-if="store.state.system.show_regions_modal" />
    <ShareModal />
    <TemplateHeader v-if="isAuth" />
    <TemplateMobileHeader v-if="isAuth" />
    <TemplateMain />
    <FloatingMobileWrapper />
    <TemplateMobileNavBar v-if="isShowNavBar && isAuth" />
  </template>
  <Transition>
    <div v-if="!isAppLoaded" class="app-overlay">
      <img src="../../../assets/images/logo.svg" alt="logotype" />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.app-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-light;
  z-index: 5;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
