<script>
import { activeScroll, disableScroll } from "@/package/helpers/scrollActions";

export default {
  name: "AppModal",
  emits: ["close"],

  props: {
    isMobileFull: {
      type: Boolean,
      default: false,
    },

    isHaveHeader: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: undefined,
    },
  },

  beforeMount() {
    disableScroll();
  },

  unmounted() {
    activeScroll();
  },
};
</script>

<template>
  <div class="app-modal" :class="{ 'app-modal_mobile-full': isMobileFull }">
    <div
      class="app-modal__wrapper wrapper"
      :class="{
        wrapper_fixed: title !== undefined,
        'wrapper_non-header': !isHaveHeader,
      }"
    >
      <div class="wrapper__header header">
        <h4 v-if="isHaveHeader">{{ title }}</h4>
        <button
          class="header__close-button close-button"
          :class="{ 'close-button_offset': isMobileFull }"
          type="button"
          @click.stop="$emit('close')"
        >
          <i class="icon-close" />
          <span v-if="isMobileFull">Отмена</span>
        </button>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.close-button {
  > span {
    display: none;
  }
}

.app-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101;
  background: $background-overlay;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    background: $background-light;
    padding: ($base-space * 10);
    border-radius: 24px;
    position: relative;
    max-height: 95vh;
    overflow: auto;

    @media (max-width: 1023px) {
      padding: ($base-space * 8);
      width: calc(100vw - 64px);
      border-radius: 16px;
    }

    @media (max-width: 767px) {
      padding: ($base-space * 6);
      width: calc(100vw - 48px);
    }

    @media (max-width: 424px) {
      width: calc(100vw - 40px);
    }

    @media (max-width: 374px) {
      width: calc(100vw - 32px);
    }

    &_fixed {
      padding-right: 0;

      .wrapper__header {
        padding-bottom: 24px;
      }
    }

    .header {
      > h4 {
        @include h-2();
        color: $text-primary;
        width: calc(100% - 48px);
      }
    }

    &_non-header {
      @media (max-width: 1023px) {
        padding: 0 20px;
        width: calc(100vw - 64px);
        border-radius: 16px;

        .header {
          display: none;
        }
      }
    }
  }

  &_mobile-full {
    .close-button {
      @media (max-width: 1023px) {
        position: static;
        width: fit-content;
        height: fit-content;
        display: block;
        margin: ($base-space * 3) ($base-space * 8) ($base-space * 3) auto;
        color: $text-accent;

        @include s-text-bold();
      }

      > i {
        @media (max-width: 1023px) {
          display: none;
        }
      }

      > span {
        @media (max-width: 1023px) {
          display: block;
        }
      }

      &_offset {
        @media (max-width: 767px) {
          margin: ($base-space * 3) ($base-space * 5) ($base-space * 3) auto;
        }

        @media (max-width: 374px) {
          margin: ($base-space * 3) ($base-space * 4) ($base-space * 3) auto;
        }
      }
    }

    .app-modal__wrapper {
      @media (max-width: 1023px) {
        border-radius: 0;
        width: 100% !important; // Раз мы включили режил full size screen то пускай здесь это будет
        height: 100% !important;
        max-width: none !important;
        max-height: none !important;
      }

      @media (max-width: 767px) {
        padding: ($base-space * 6);
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
