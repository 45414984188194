import HttpClient from "@/api";
import { getFilesFromPhotos } from "@/utils/files";
import parseDate from "date-fns/parse";
import API from "@/api";
import { format } from "date-fns";
import responseErrorMessageHandler from "@/utils/responseErrorMessageHandler";

const getLoadEventsUrl = (payload) => {
  if (payload.communityId) {
    return `api/communities/${payload.communityId}/events`;
  }
  if (payload.courseId) {
    return `api/courses/${payload.courseId}/events`;
  }
  return "/api/events";
};

const state = {
  events: [],
  memberEventList: [],
  perPage: 15,
  page: 1,
  lastPage: 1,
  total: 0,
  current_page: 1,
  isAddingEvent: false,
  isLoading: false,
  isLoadMoreLoading: false,
  isPageLoaded: false,
};

const mutations = {
  SET_EVENTS(store, payload) {
    store.events = payload;
  },

  RESET_EVENTS(store) {
    store.events = [];
    store.page = 1;
    store.lastPage = 1;
    store.current_page = 1;
    store.total = 0;
    store.isLoading = false;
    store.isLoadMoreLoading = false;
    store.isPageLoaded = false;
  },

  RESET_MEMBER_LIST_EVENTS(store) {
    store.memberEventList = [];
    store.total = 0;
  },

  UPDATE_EVENTS(store, payload) {
    store.events = [...store.events, ...payload.events];
    store.total = payload.meta.total;
    store.page = payload.meta.current_page;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },

  TOGGLE_LOAD_MORE_LOADING(store, payload) {
    store.isLoadMoreLoading = payload ?? !store.isLoadMoreLoading;
  },

  TOGGLE_PAGE_LOADING(store) {
    store.isPageLoaded = true;
  },

  SET_ADDING_EVENT(store, payload) {
    store.isAddingEvent = payload;
  },

  SET_PER_PAGE(store, payload) {
    store.current_page = payload;
  },

  GET_MEMBERS_LIST(store, { members, infinityScrolled }) {
    if (infinityScrolled) {
      store.memberEventList = [...store.memberEventList, ...members];
    } else {
      store.memberEventList = members;
    }
  },

  GET_MEMBERS(store, payload) {
    store.total = payload;
  },

  SET_PAGE(store) {
    store.page += 1;
  },

  RESET_PAGE(store) {
    store.page = 1;
  },

  SET_LAST_PAGE(store, payload) {
    store.lastPage = payload;
  },
};

const actions = {
  async loadEvents(store, payload) {
    const loadingAction = payload.loadMore
      ? "TOGGLE_LOAD_MORE_LOADING"
      : "TOGGLE_LOADING";

    try {
      store.commit(loadingAction, true);
      const params = {
        per_page: store.state.perPage,
        page: payload.page,
        ...(payload.filters ?? {}),
        types: [],
      };

      if (payload.searchQuery) {
        params.query = payload.searchQuery;
      }
      if (payload.communityId) {
        params.community_id = payload.communityId;
      }

      const paramsType = {};

      const getParamsType = () => {
        if (payload.communityId) {
          paramsType.value = {
            ...params,
            types: ["community"],
          };
        } else if (payload.courseId) {
          paramsType.value = {
            ...params,
            types: ["course"],
          };
        } else {
          paramsType.value = {
            ...params,
            types: ["public", "course", "community"],
          };
        }
      };

      getParamsType();

      const response = await HttpClient.get(getLoadEventsUrl(payload), {
        params: paramsType.value,
      });

      if (!response.data) {
        throw new Error("No data");
      }
      store.commit("SET_LAST_PAGE", response.data.meta.last_page);

      store.commit("UPDATE_EVENTS", {
        events: response.data.data,
        meta: response.data?.meta,
        loadMore: payload.loadMore,
        filters: payload.filters,
        infinityScroll: payload.infinityScroll ?? false,
      });

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject();
    } finally {
      store.commit(loadingAction, false);
      store.commit("TOGGLE_PAGE_LOADING");
    }
  },
  async addEvent({ commit }, payload) {
    try {
      commit("SET_ADDING_EVENT", true);
      const {
        address,
        city,
        date,
        description,
        name,
        organizer,
        photos,
        status,
        highlights,
        time,
      } = payload;
      const files = getFilesFromPhotos(photos).map((file, idx) => ({
        file,
        order: idx,
      }));

      const dateAt = format(
        parseDate(`${date} ${time}`, "dd.MM.yyyy HH:mm", new Date()),
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      );

      const response = await HttpClient.postForm(
        `api/communities/${payload.communityId}/events`,
        {
          region_id: city.id,
          name,
          description,
          place: address,
          date_at: dateAt,
          organizer,
          highlight: highlights,
          files: files,
          min_tariff_id: status.id,
        }
      );
      return Promise.resolve(response.data?.data?.id);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("SET_ADDING_EVENT", false);
    }
  },

  changeEvent(store, payload) {
    const params = {
      name: payload.name,
      region_id: payload.city.id,
      description: payload.description,
      place: payload.address,
      date_at: format(
        parseDate(
          `${payload.date} ${payload.time}`,
          "dd.MM.yyyy HH:mm",
          new Date()
        ),
        "dd-MM-yyyy HH:mm"
      ),
      organizer: payload.organizer,
      highlight: payload.highlights,
      min_tariff_id: payload.status.id,
    };

    const deleteMediaNormalizeEvent = [];
    const addMediaNormalizeEvent = [];

    payload.deleteMedia
      .map((i) => i.id)
      .forEach((i) => {
        deleteMediaNormalizeEvent.push(
          store.dispatch("deleteMediaPost", {
            media_id: i.toString(),
            communityId: payload.communityId,
            eventId: payload.eventId,
          })
        );
      });

    getFilesFromPhotos(payload.addMedia).forEach((i) => {
      addMediaNormalizeEvent.push(
        store.dispatch("setMediaPost", {
          file: i,
          communityId: payload.communityId,
          eventId: payload.eventId,
        })
      );
    });

    return new Promise((resolve, reject) => {
      HttpClient.patch(
        `api/communities/${payload.communityId}/events/${payload.eventId}`,
        params
      )
        .then((response) => {
          Promise.all([
            ...deleteMediaNormalizeEvent,
            ...addMediaNormalizeEvent,
          ]).then(() => {
            resolve(response);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setMediaPost(_, payload) {
    const params = {
      file: payload.file,
    };
    return new Promise((resolve, reject) => {
      HttpClient.postForm(
        `api/communities/${payload.communityId}/events/${payload.eventId}/media/add`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteMediaPost(_, payload) {
    const params = {
      media_id: payload.media_id,
    };
    return new Promise((resolve, reject) => {
      HttpClient.post(
        `api/communities/${payload.communityId}/events/${payload.eventId}/media/delete`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadEventById(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/events/${data}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadEventsDates(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/events/dates`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  participateToEvent(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/events/${data}/participate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unparticipateToEvent(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/events/${data}/unparticipate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  purchaseTicket(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/events/${data}/participate/paid`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async loadMembersEventList(store, payload) {
    const params = {
      page: store.state.current_page,
    };

    if (payload.infinityScrolled && params.page <= store.state.lastPage) {
      params.page++;
      store.commit("SET_PER_PAGE", params.page);
    } else if (payload.infinityScrolled) {
      return;
    } else if (params.page > store.state.lastPage) {
      return;
    }

    if (typeof payload.community_id === "undefined") {
      return;
    }

    const response = await HttpClient.get(
      `api/communities/${payload.community_id}/events/${payload.event_id}/users`,
      {
        params,
      }
    );

    store.commit("GET_MEMBERS_LIST", {
      members: response.data.data.map((item) => {
        return {
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          media: item.media.map((j) => j.url),
        };
      }),
      infinityScrolled: payload.infinityScrolled,
    });

    store.commit("GET_MEMBERS", response.data.meta.total);
  },

  sendNotificationEventMembers(store, payload) {
    const params = {
      title: payload.title,
      body: payload.body,
      if_html: false,
    };

    return new Promise((resolve, reject) => {
      HttpClient.postForm(
        `api/communities/${payload.community_id}/events/${payload.event_id}/notification`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          responseErrorMessageHandler(
            error,
            "Не удалось отправить уведмоление"
          );
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
