<script setup>
import ContentBox from "@/components/Ui/ContentBox.vue";
import { useStore } from "vuex";
import AppInput from "@/components/Ui/AppInput.vue";
import { onBeforeMount, reactive } from "vue";
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRouter } from "vue-router";

const store = useStore();

const { push } = useRouter();

const formData = reactive({
  email: "",
});

const rules = {
  formData: {
    email: {
      required: helpers.withMessage("Заполните поле", required),
      email: helpers.withMessage("Неверный формат", email),
    },
  },
};

const v$ = useVuelidate(rules, { formData });

const handleSelectPaymentCheck = (id) => {
  store.commit("customTariff/SELECT_PAYMENT_CHECK", id);
};

const handlePay = async () => {
  v$.value.$validate();
  if (v$.value.$error) {
    store.commit("system/SET_NOTIFICATION", {
      type: "error",
      message: `Неверный Email`,
    });
  } else if (
    !store.state.customTariff.selectedPaymentCheckId &&
    store.state.customTariff.paymentMethod === "check"
  ) {
    store.commit("system/SET_NOTIFICATION", {
      type: "error",
      message: `Выберите счет`,
    });
  } else {
    try {
      const url = await store.dispatch("customTariff/pay", {
        email: formData.email,
      });
      if (url && store.state.customTariff.paymentMethod === "card") {
        window.open(url);
      } else {
        push({ name: "ProfileCustomTariffSuccess" });
      }
    } catch (error) {
      store.commit("system/SET_NOTIFICATION", {
        type: "error",
        message: `Не получилось создать счет`,
      });
    }
  }
};

const handleAddPaymentMethod = () => {
  push({ name: "ProfileCustomTariffAddPayment" });
};

onBeforeMount(() => {
  store.dispatch("customTariff/loadPaymentMethods");
});
</script>

<template>
  <ContentBox
    :title="
      store.state.customTariff.paymentMethod === 'card'
        ? 'Оплата картой'
        : 'Оплата по счету'
    "
  >
    <div class="email-input">
      <AppInput
        id="c-email"
        v-model="formData.email"
        label="Электронная почта"
        :error="v$.formData.email?.$errors[0]?.$message"
        input-mode="text"
        required
        clear
      />
      <div>Укажите почту, на которую будет отправлен билет</div>
    </div>
    <div
      v-if="store.state.customTariff.paymentMethod === 'check'"
      class="payments"
    >
      <div class="header">
        <div class="title">Ваши реквизиты</div>
        <button
          type="button"
          class="action text-button"
          @click="handleAddPaymentMethod"
        >
          Добавить
          <i class="icon-plus" />
        </button>
      </div>
      <div class="list">
        <div
          v-for="item in store.state.customTariff.savedPaymentMethods"
          :key="item.id"
          class="list-item"
          :class="{
            selected:
              store.state.customTariff.selectedPaymentCheckId === item.id,
          }"
          @click="handleSelectPaymentCheck(item.id)"
        >
          <div class="icon">
            <i class="icon-bill"></i>
          </div>
          <div class="info">
            <div class="name">{{ item.organization_name }}</div>
            <div class="inn">{{ item.organization_type }} "{{ item.inn }}"</div>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="button button_full-width pay"
      :disabled="store.state.customTariff.isPurchasing"
      @click="handlePay"
    >
      Оплатить
    </button>
    <p class="condition-payment">
      <span class="condition-payment__description description">
        <span>
          Чтобы получить ОФД чек, убедитесь, что у вас введен корректно Email в
          вашем профиле.
        </span>
        Нажимая кнопку «Оплатить», вы принимаете
        <span class="description__link-list link-list">
          <a
            class="link-list__link"
            href="/docs/subscription-rules.pdf"
            download="правила предоставления подписки.pdf"
          >
            правила предоставления подписки,
          </a>

          <a
            class="link-list__link"
            href="/docs/privacy-policy.pdf"
            download="политика конфиденциальности.pdf"
          >
            политика конфиденциальности,
          </a>

          <a
            class="link-list__link"
            href="/docs/user-agreement.pdf"
            download="пользовательское соглашение.pdf"
          >
            пользовательское соглашение,
          </a>

          <a
            class="link-list__link"
            href="/docs/rdo-details.pdf"
            download="реквизиты компании.pdf"
          >
            реквизиты компании
          </a>
        </span>
      </span>
    </p>
  </ContentBox>
</template>

<style scoped lang="scss">
.email-input {
  display: flex;
  flex-direction: column;
  gap: $base-space * 2;
  color: $text-third;
  @include xs-text;
}

.pay {
  margin-top: $base-space * 6;
}

.payments {
  margin-top: $base-space * 6;

  .header {
    display: flex;
    justify-content: space-between;
    .title {
      @include m-text-bold;
    }
    .action {
      display: flex;
      align-items: center;
      gap: $base-space;

      i {
        width: 24px;
        height: 24px;
      }
    }
  }
  .list {
    margin-top: $base-space * 3;
    display: flex;
    flex-direction: column;
    gap: $base-space * 3;

    .list-item {
      display: flex;
      border-radius: 16px;
      padding: $base-space * 4 $base-space * 5;
      border: 1px solid #c9cbd7;
      gap: $base-space * 3;

      &:hover {
        cursor: pointer;
      }

      .icon {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background-color: #ebedfa;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          width: 24px;
          height: 24px;
          background-color: $text-accent;
        }
      }

      .info {
        .name {
          @include m-text-bold;
        }
        .inn {
          @include xs-text;
          color: $text-third;
        }
      }

      &.selected {
        border-color: $text-accent;
      }
    }
  }
}

.condition-payment {
  display: grid;
  grid-template-rows: repeat(3, fit-content(10px));
  gap: 16px;
  margin: ($base-space * 4) 0 0;

  .description {
    @include s-text();
    color: $text-fourth;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .link-list {
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      &__link {
        @include s-text-link();
        background: none;
        border: none;
        color: $text-accent;
        text-decoration: underline;
        white-space: nowrap;
        margin: 0 2px;

        &:visited {
          color: $text-accent;
        }
      }
    }
  }
}
</style>
