import HttpClient from "@/api";
import * as Yup from "yup";

Yup.addMethod(
  Yup.string,
  "phoneAvailableForCorporateTariff",
  function method() {
    return this.test(`phoneAvailable`, "", function validator(value) {
      const validate = async (ctx) => {
        const { path, createError } = ctx;

        if (value.length !== 12) {
          return true;
        }

        try {
          await HttpClient.post("api/corporate-tariff/phone-validate", {
            phone: value,
          });
          return true;
        } catch (error) {
          if (error.response.status === 404) {
            return createError({ path, message: "Пользователь не найден" });
          }
          if (error.response.data?.data?.message === "tariff already exists") {
            return createError({
              path,
              message: "Тариф уже активирован",
            });
          }
          return createError({ path, message: "Что-то пошло не так" });
        }
      };

      return validate(this);
    });
  }
);

export { Yup };
export default Yup;
