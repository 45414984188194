<script setup>
import NotificationCard from "@/components/Notifications/NotificationCard.vue";
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";
import PageLoader from "@/components/Layouts/MainLayout/PageLoader.vue";
import { getTextDateNotification } from "../../package/helpers/notification-date-format";
import moment from "moment";
import AppObserver from "@/components/Ui/AppObserver.vue";
import NotificationEmpty from "@/components/Notifications/NotificationEmpty.vue";

defineOptions({
  name: "NotificationsList",
});

const emit = defineEmits([
  "intersectedNotificationHandler",
  "closeDropdownHandler",
]);

const store = useStore();

const notifications = computed(
  () => store.state.notifications?.notificationsList
);

const isEmptyList = computed(() => {
  if (Array.isArray(notifications.value)) {
    return notifications.value.length;
  } else {
    return false;
  }
});

const isEqualDatesFirstItem = (index) => {
  return index !== 0;
};

const isEqualDates = (index) => {
  const thisDate = getTextDateNotification(
    notifications.value[index]?.created_at
  );
  const prevDate = getTextDateNotification(
    notifications.value[index - 1]?.created_at
  );

  const thisDay = notifications.value[index]?.created_at;
  const prevDay = notifications.value[index - 1]?.created_at;

  const thisMessageDate = moment(thisDay).format("YYYY-MM-DD");
  const prevMessageDate = moment(prevDay).format("YYYY-MM-DD");

  const startWeek = moment().isoWeekday(1); // Начало недели
  const endWeek = moment().isoWeekday(7); // Конец недели

  if (notifications.value[index - 1]?.created_at) {
    if (thisDate === "На этой неделе") {
      return (
        thisDate === "На этой неделе" &&
        !moment(thisMessageDate).isSame(prevMessageDate) &&
        moment(prevMessageDate).isBetween(startWeek, endWeek) &&
        prevDate === "Сегодня"
      );
    } else {
      return (
        thisDate === "Ранее" &&
        !moment(thisMessageDate).isSame(prevMessageDate) &&
        (prevDate === "Сегодня" || prevDate === "На этой неделе")
      );
    }
  } else {
    if (thisDate === "На этой неделе") {
      return thisDate === "На этой неделе" && prevDate !== "На этой неделе";
    } else {
      return thisDate === "Ранее" && prevDate !== "Ранее";
    }
  }
};

const isLoading = computed(
  () => store.state.notifications.isLoadingNotification
);
</script>

<template>
  <div v-if="isEmptyList || isLoading" class="notifications-list">
    <div class="notifications-list__list-element today">
      <template v-for="(notification, index) in notifications" :key="index">
        <div
          v-if="getTextDateNotification(notification.created_at) === 'Сегодня'"
          class="today__item item"
        >
          <span class="item__divider" />

          <NotificationCard
            :notification-item="notification"
            @closeDropdownHandler="emit('closeDropdownHandler')"
          />
          <div v-if="!isEqualDatesFirstItem(index)" class="item__date">
            <span>
              {{ getTextDateNotification(notification.created_at) }}
            </span>
          </div>
        </div>
        <div
          v-if="
            getTextDateNotification(notification.created_at) ===
            'На этой неделе'
          "
          class="today__item item"
        >
          <span class="item__divider" />

          <NotificationCard
            :notification-item="notification"
            @closeDropdownHandler="emit('closeDropdownHandler')"
          />
          <div v-if="isEqualDates(index)" class="item__date">
            <span>
              {{ getTextDateNotification(notification.created_at) }}
            </span>
          </div>
        </div>
        <div
          v-if="getTextDateNotification(notification.created_at) === 'Ранее'"
          class="today__item item"
        >
          <span class="item__divider" />

          <NotificationCard
            :notification-item="notification"
            @closeDropdownHandler="emit('closeDropdownHandler')"
          />
          <div v-if="isEqualDates(index)" class="item__date">
            <span>
              {{ getTextDateNotification(notification.created_at) }}
            </span>
          </div>
        </div>
      </template>
      <AppObserver @intersect="emit('intersectedNotificationHandler')" />
    </div>
  </div>
  <template v-else>
    <PageLoader v-if="notifications === null" class="loader" is-loading />
    <NotificationEmpty
      v-else
      description="У вас еще нет уведомлений"
      icon="icon-notification"
      class="empty-list"
    />
  </template>
</template>

<style lang="scss" scoped>
.notifications-list {
  position: relative;
  width: 100%;
  display: flex;
  overflow: auto;

  .today {
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: left;

    .item {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      .item__divider:not(:last-child) {
        display: block;
        width: 100%;
        background: $stroke-fourth;
        height: 1px;
      }

      &__date {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: left;
        padding: 0 20px;
        border-radius: 16px;
        margin: $base-space * 6 0 $base-space * 3;
        text-align: left;

        > span {
          color: $text-primary;

          @include h-3();
        }
      }
    }
  }
}

.loader {
  align-items: start;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}

.empty-list {
  width: 100%;
  margin-top: $base-space * 18;
  padding: 0 ($base-space * 8);
}
</style>
