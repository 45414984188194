import HttpClient from "@/api";

const state = {
  services: [],
  perPage: 15,
  page: 1,
  lastPage: 1,
  total: 0,
  isLoading: false,
  isLoadMoreLoading: false,
  isPageLoaded: false,
};

const mutations = {
  SET_SERVICES(store, payload) {
    store.services = payload;
  },
  RESET_SERVICES(store) {
    store.services = [];
    store.page = 1;
    store.lastPage = 1;
    store.total = 0;
    store.isLoading = false;
    store.isLoadMoreLoading = false;
    store.isPageLoaded = false;
  },

  UPDATE_SERVICES(store, payload) {
    store.services = [
      ...(payload.loadMore ? store.services : []),
      ...payload.services,
    ];
    store.lastPage = payload.meta.last_page;
    store.page = payload.meta.current_page;
    store.total = payload.meta.total;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },

  TOGGLE_LOAD_MORE_LOADING(store, payload) {
    store.isLoadMoreLoading = payload ?? !store.isLoadMoreLoading;
  },

  TOGGLE_PAGE_LOADING(store) {
    store.isPageLoaded = true;
  },
};

const actions = {
  async loadServices(store, payload) {
    const loadingAction = payload.loadMore
      ? "TOGGLE_LOAD_MORE_LOADING"
      : "TOGGLE_LOADING";
    try {
      store.commit(loadingAction, true);
      const params = {
        page: payload.loadMore ? store.state.page + 1 : 1,
        per_page: store.state.perPage,
      };

      if (payload.searchQuery) {
        params.query = payload.searchQuery;
      }

      const response = await HttpClient.get(`api/useful-services`, {
        params,
      });
      if (!response.data) {
        throw new Error("No data");
      }
      store.commit("UPDATE_SERVICES", {
        services: response.data.data,
        meta: response.data?.meta,
        loadMore: payload.loadMore,
      });
    } catch (error) {
      console.error(error);
    } finally {
      store.commit(loadingAction, false);
      store.commit("TOGGLE_PAGE_LOADING");
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
